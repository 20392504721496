




























































































.v-picker,
::v-deep .theme--light.v-picker__body {
    background-color: transparent;
}
.v-picker {
    width: 100%;
}
::v-deep .v-date-picker-table--date .v-btn {
    height: 40px;
    width: 40px;
    border-radius: 100% !important;
}
::v-deep .v-date-picker-table .v-btn.v-btn--active.secondary {
    color: #fff;
}
