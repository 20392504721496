



















.text-list,
::v-deep.text-list .page-text{
    line-height: 1.6;
}
.text-list >.row:not(:first-child){
    margin-top: -8px;
}
