










































::v-deep .v-dialog--default {
    max-height: 100vh !important;
    border-radius: 0;
    margin: 0;
    box-shadow: none;
    > .v-card {
        > .v-card__title {
            font-size: 2rem;
            font-weight: 700;
            padding: 20px 16px;
            & + .v-card__text {
                padding-top: 0;
            }
        }
        > .v-card__text {
            color: #111;
            padding: 20px 16px;
        }
    }
    &__close {
        width: 58px !important;
        height: 58px !important;
        margin: -20px -16px -20px auto;
    }
}
