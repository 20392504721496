




















.border-list::v-deep > .row.no-gutters > [class*="col"]:not(:first-child) .border-list__top-divider {
    display: none;
}
.v-application .border-list--grey::v-deep .border-list__top-divider {
    background-color: var(--border-color) !important;
    border-color: var(--border-color) !important;
}
